// extracted by mini-css-extract-plugin
export var wrapper = "ongoingActivity-module--wrapper--a7Afe";
export var label = "ongoingActivity-module--label--ZoMuC";
export var filterWrapper = "ongoingActivity-module--filterWrapper--VXSIb";
export var sticky = "ongoingActivity-module--sticky--AoqIM";
export var year = "ongoingActivity-module--year--3Iceo";
export var accordion = "ongoingActivity-module--accordion--qxl5Y";
export var resultsWrapper = "ongoingActivity-module--resultsWrapper--m4P38";
export var root = "ongoingActivity-module--root--oNJWX";
export var titleArea = "ongoingActivity-module--titleArea--Bw3Bl";
export var selectWrapper = "ongoingActivity-module--selectWrapper--2ku9U";
export var yearResources = "ongoingActivity-module--yearResources--Lrq6V";
export var title = "ongoingActivity-module--title--hg0Gi";
export var titleMain = "ongoingActivity-module--titleMain--3GNLR";
export var currentPast = "ongoingActivity-module--currentPast--tN2j3";
export var themeAccordion = "ongoingActivity-module--themeAccordion--iwxy5";
export var ml = "ongoingActivity-module--ml--6EdZt";
export var searchWrapper = "ongoingActivity-module--searchWrapper--MTx15";
export var filterPastYear = "ongoingActivity-module--filterPastYear--4xX9D";